import * as React from 'react'
import { WithIntercom } from './src/components/WithIntercom/withIntercom'
import { ReactNode } from 'react'
import { Location } from '@reach/router'

export default ({ element }: { element: ReactNode }) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    // wrapping in Location ensures, that useLocation hook can be called inside WithIntercom
    return (
        <Location>
            {(props) => {
                return <WithIntercom>{element}</WithIntercom>
            }}
        </Location>
    )
}
