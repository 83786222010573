'use client'
import React, { ReactNode, useEffect } from 'react'
import { IntercomProvider, useIntercom } from 'react-use-intercom'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useLocation } from '@reach/router'

const INIT_DELAY = 3000
const INIT_DELAY_WHEN_OPEN_IMMEDIATELY = 0

function useOpenChatImmediately() {
    const params = useQueryParams()

    const openChatImmediately = params.chat !== null && params.chat !== undefined
    return { openChatImmediately }
}

function IntercomImmediatelyShowController({ children }: { children: ReactNode }) {
    const { show, hide } = useIntercom()
    const { openChatImmediately } = useOpenChatImmediately()
    const location = useLocation()

    useEffect(() => {
        if (openChatImmediately) {
            console.info('Intercom showed immediately')
            setTimeout(() => {
                show()
            }, 0)
        }
    }, [openChatImmediately, show])

    useEffect(() => {
        hide?.()
    }, [location.pathname])

    return <>{children}</>
}

export const WithIntercom: React.FC<
    React.PropsWithChildren<{
        appId?: string
        autoBoot?: boolean
    }>
> = ({ children, appId = 'yy8r06ql', autoBoot = true }) => {
    const { openChatImmediately } = useOpenChatImmediately()
    const initializeDelay = openChatImmediately ? INIT_DELAY_WHEN_OPEN_IMMEDIATELY : INIT_DELAY

    return (
        <IntercomProvider autoBoot={autoBoot} appId={appId} initializeDelay={initializeDelay}>
            <IntercomImmediatelyShowController>{children}</IntercomImmediatelyShowController>
        </IntercomProvider>
    )
}
